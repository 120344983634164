
  <div class="row">
  <div class=" select-trip" [ngClass]="{'input-opened-options':isOpen, 'input-opened':isOpen,'col-lg-2':isOpen,'col-lg-1':!isOpen}">
    <div class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-bg-white theme-search-area-section-mr">
        <select #t (change)="callType(t.value)" [(ngModel)]="journeyType" class="form-control" id="exampleFormControlSelect1">
          <option value='One Way' selected>One Way</option>
          <option value='Return'>Return</option>
        </select>
    </div>
  </div><!-- /.col-lg-6 -->
    <div  (click)="toggle($event)"  class=" {{isFocused ? 'focus':''}} "  [ngClass]="{'input-opened':isOpen,'col-lg-5':isOpen,'col-md-5':!isOpen}">
      <div class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-bg-white theme-search-area-section-mr">
        <div class="theme-search-area-section-inner">
          <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>
          <input  class="theme-search-area-section-input " type="text"   [(ngModel)]="traveldate"  placeholder="Depature" >
        </div>
      </div>
    </div>
    <div  (click)="toggle($event)"  class="{{isFocused ? 'focus':''}} "  [ngClass]="{'input-opened':isOpen,'col-lg-5':isOpen,'col-md-5':!isOpen}">
      <div class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-bg-white theme-search-area-section-mr">
        <div class="theme-search-area-section-inner">
          <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>
          <input  class="theme-search-area-section-input " type="text"  [(ngModel)]="returndata" placeholder="One Way" >
        </div>
      </div>
    </div>
    <div class="col-md-1">
      <button type="submit"  class="theme-search-area-submit theme-search-area-submit-curved  theme-search-area-submit-glow">SEARCH</button>                          

    </div>

  </div>
  <div class="row">
  <div class="col-md-12" >
    <ul class="dropdown-options" [ngClass]="{'input-opened-options':isOpen}"  [@slideInOut]>
      <ng-container>
        
        <!-- <bs-daterangepicker-inline class="focusDatePick"  
        (bsValueChange)="onValueChange($event)"
        [bsValue]="bsInlineRangeValue"
        [minDate]="today" 
        triggers="keydown:click"
        [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }" >
      </bs-daterangepicker-inline> -->
      <div class="rows">
        <div class="col-md-4">
          <h4 class=" city-labels-sm hidden-sm">
            {{depatureTown}}
            →
            {{destinationTown}}
          </h4>
        </div>
        <div class="col-md-2 col-md-offset-4 pull-right">
          <div (click)="doneClick()" class="btn btn-default  theme-search-area-done-btn  theme-search-area-submit-curved  theme-search-area-submit-glow">DONE</div>
        </div>
      </div>
      
      </ng-container>
  
    </ul>
  </div>
  </div>







<!-- 

<div class="dropdown-wrapper {{className}} {{isFocused ? 'focus':''}}" [ngClass]="{'is-open':isOpen, 'disabled':isReadOnly}"  >
  
  <div *ngIf="isOpen" class="box"  class="theme-search-area-section first theme-search-area-section-fade-white theme-search-area-section-mr theme-search-area-section-sm theme-search-area-section-curved">
    <div class="dropdown-selected-list theme-search-area-section-inner">
      <select  [(ngModel)]="journeyType" class="form-control" id="exampleFormControlSelect1">
        <option value='One Way' selected>One Way</option>
        <option value='Return'>Return</option>
      </select>
    </div>
  </div>  
  <div class="box" (click)="toggle($event)" class="theme-search-area-section first theme-search-area-section-fade-white theme-search-area-section-mr theme-search-area-section-sm theme-search-area-section-curved">
    <div [ngClass]="{'is-open-selected':isOpen}"  class="dropdown-selected theme-search-area-section-inner">
      <i class="theme-search-area-section-icon lin  lin-calendar"></i>
      <input  class="theme-search-area-section-input "  [(ngModel)]="traveldate" type="text"  placeholder="Depature" >
    </div>
  </div>
  <div class="box" (click)="toggle($event)" class="theme-search-area-section first theme-search-area-section-fade-white theme-search-area-section-mr theme-search-area-section-sm theme-search-area-section-curved">
    <div [ngClass]="{'is-open-selected':isOpen}"  class="dropdown-selected theme-search-area-section-inner">
      <i class="theme-search-area-section-icon lin  lin-calendar"></i>
      <input   class="theme-search-area-section-input "  [(ngModel)]="returndata " type="text"  placeholder="Return" >
    </div>
  </div>
    <ul class="dropdown-options" >
      <ng-container>
        
        <bs-daterangepicker-inline  
        (bsValueChange)="onValueChange($event)"
        [bsValue]="bsInlineRangeValue"
        [minDate]="today" 
        [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }" >
      </bs-daterangepicker-inline>

      <div  class=" pull-right theme-search-area-done-btn  theme-search-area-submit-curved  theme-search-area-submit-glow">DONE</div>

      </ng-container>
  
    </ul>
  </div> -->
