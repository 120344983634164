<div id="tophome"></div>
<div class="header-mobile">
    <nav class="mobile-nav">
      <div class="mobile-item-cntnr ">
        <a href="https://buupass.com">
          <div class="mobile-item " data-index="2">
              <div class="mobile-icon ixi-icon-bus-home">
                  <i class="fa fa-bus" aria-hidden="true"></i>
              </div>
              <div class="mobile-text">BUSES</div>
          </div>
        </a>
      </div>
        <div class="mobile-item-cntnr ">
          <a href="https://metickets.krc.co.ke/" rel="noreferrer" target="_blank">
            <div class="mobile-item" data-index="3">
                <div class="mobile-icon ixi-icon-train-home train-icon">
                    <i class="fa fa-subway" aria-hidden="true"></i>

                </div>
                <div class="mobile-text">TRAINS</div>
            </div>
          </a>
        </div>
        <div class="mobile-item-cntnr ">
          <a href="index.html" rel="noreferrer" >
            <div class="mobile-item selected" data-index="0">
                <div class="mobile-icon ixi-icon-flight-home flight-icon">
                    <i class="fa fa-plane" aria-hidden="true"></i>
                </div>
                <div class="mobile-text">FLIGHTS</div>
            </div>
          </a>
        </div>
        <div class="mobile-item-cntnr ">
          <a [routerLink]="['/hotels']" rel="noreferrer" target="_blank">
            <div class="mobile-item" data-index="0">
                <div class="mobile-icon product-icon ixi-icon-hotel-home hotel-icon">
                    <i class="fa fa-building" aria-hidden="true"></i>
                </div>
                <div class="mobile-text">HOTELS</div>
            </div>
          </a>
        </div>
        <div class="nav-border"></div>
    </nav>
</div>
