
<div class=" hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg  visible-md">
  <app-headerhome ></app-headerhome>
</div>
<div >
  <div class="MainView _pos-r">
    <div class=" hidden-md hidden-lg ">
        <app-search-form></app-search-form>
    </div>
      <div  class="theme-hero-area-body _mt-90 visible-lg visible-md  _w-f buupass-container" >
            <div class="theme-search-area-tabs ">
              <div class="text-center theme-search-area-tabs-header  _mr-20 mt-10">
                <h1 class="banner-text">
                  <div class=" hidden-xs">
                  The easiest way to book airline tickets in kenya.
                  </div>
                    <img class=" hidden-md hidden-lg _ml-50" src="assets/img/company1/logo-mobile.jpg" alt="buupass.com" alt="Image Alternative text" title="Image Title"/>
                </h1>
              </div>
              
              <div class="tabbable " #stickyForm  [ngClass]="sticky ? 'sticky':'' " >
                <div class="tab-content   _bg-w tab-content-curved">
                  <div class="tab-pane active" id="SearchAreaTabs-4" >
                    <div class="theme-search-area-options theme-search-area-options-dot-primary-inverse clearfix">
                      <div class="btn-group theme-search-area-options-list" >
                        <label class="custom-lable _pr-10 ">
                          <input type="radio" name="1" id="1" class="with-gap"  [value]="0" [(ngModel)]="radio_button_value" (click)="onRadioChange('return')"><span class="_ml-5">Round Trip</span>
                        </label>
                        <label class="custom-lable ">
                          <input type="radio" name="2" id="2" class="with-gap"  [value]="1" [(ngModel)]="radio_button_value"  (click)="onRadioChange('One way')"><span class="_ml-5">One Way</span>
                        </label>
                      </div>
                    </div>
                    <div class="theme-search-area " >
                        <form  autocomplete="off" novalidate [formGroup]="reactiveForm" (ngSubmit)="submitForm()">
                         
                          <div class="row" >
                                <div class="col-md-2 ">
                                    <app-from 
                                        formControlName="pickup"
                                      [options]="sampleData"
                                      [ngClass]="{ 'ng-invalid-custom': submitted && f.pickup.errors }"
                                      [placeholder]="fromPlaceholder"
                                      [isReadOnly]="isReadOnly">
                                    </app-from>
                                    <span *ngIf="submitted && f.pickup.errors" [@slideInOut] class="invalid-select">Please enter a city or airport
                                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div class="col-md-2 ">
                                    <app-dropdown
                                      formControlName="dropoff"
                                      [options]="sampleData"
                                      [ngClass]="{ 'ng-invalid-custom': submitted && f.dropoff.errors }"
                                      [placeholder]="toPlaceholder"
                                      [isReadOnly]="isReadOnly">
                                    </app-dropdown>
                                    <span *ngIf="submitted && f.dropoff.errors" [@slideInOut] class="invalid-select">Please enter a city or airport
                                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div class="col-md-2 depature-container">
                                  <mat-form-field  appearance="fill" class="full-width">
                                    <mat-label>Depature</mat-label>
                                    <input  matInput [min]="minDate" placeholder="MM/DD/YYYY" (dateChange)="onTravelDateChange($event)" [matDatepicker]="depaturepicker" formControlName="traveldate">
                                    <mat-datepicker-toggle matIconSuffix [for]="depaturepicker"></mat-datepicker-toggle>
                                    <mat-datepicker #depaturepicker></mat-datepicker>
                                  </mat-form-field>
                                  
                                </div>
                                <div class="col-md-2 return-container ">
                                    <mat-form-field>
                                      <mat-label>Return</mat-label>
                                      <input matInput placeholder="MM/DD/YYYY" [min]="returnMinDate" [matDatepicker]="picker" formControlName="returndate">
                                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                      <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2">
                                    <app-passengers className="change-city" formControlName="passenger"
                                    >
                                    </app-passengers>
                                    <span *ngIf="submitted && f.passenger.errors" [@slideInOut] class="invalid-select">Please select number of passengers
                                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div class="col-md-1">
                                  <button matRipple mat-flat-button type="submit"  class=" _ml-10 theme-search-area-done-btn">Search</button>
                                  <!-- <button type="submit"  class="theme-search-area-submit btn-block theme-search-area-submit-curved  theme-search-area-submit-glow">SEARCH</button> -->
                                </div>
                        </div>
                        </form>
                  </div>
                  <!-- <div class="text-center" style="">
                    <img  height="60" alt="" class="text-center " src="assets/img/pay_via.png">
                </div> -->
                </div>
              </div>
              </div>
          </div>
      </div>
      <div class="theme-page-section visible-lg visible-md _mt-10 theme-page-section-sm ">
          <div class="theme-page-section-header theme-page-section-header-white">
            <div class="buupass-home-section">
            <div class="buupass-container">
                <div class="feature-list">
                    <div class="list-item">
                      <div class="row">
                          <div class="col-md-2 col-xs-2">
                              <img src="assets/img/company1/payments.png" class="_mt-20" style="height: 45px;"/>
                          </div>
                          <div class="col-md-8 col-xs-8">
                            <h5 class="font-desc">Flexible payments</h5>
                            <p class="content">Pay with M-pesa, credit cards or bank transfers. We have you fully covered.</p>
                          </div>
                     </div>
                    </div>
                    <div class="list-item">
                      <div class="row">
                          <div class="col-md-2 col-xs-2">
                              <img src="assets/img/company1/support.png" class="_mt-20" style="height: 45px;"/>
                          </div>
                          <div class="col-md-8 col-xs-8">
                            <h5 class="font-desc">Great customer care</h5>
                            <p class="content">Get excellent customer service available 8:00 AM to 10:00 PM, via phone, chat or email.</p>
                          </div>
                     </div>
                    </div>
                    <div class="list-item">
                      <div class="row">
                          <div class="col-md-2 col-xs-2">
                              <img src="assets/img/company1/convenience.png" class="_mt-20" style="height: 45px;"/>
                          </div>
                          <div class="col-md-8 col-xs-8">
                            <h5 class="font-desc">Best prices</h5>
                            <p class="content">We are comparing hundreds travel websites to find best price for you.</p>
                          </div>
                     </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="theme-page-section-home ">
        <div class="buupass-container">
          <div class="theme-page-section-header">
            <h5 class="theme-page-section-title">Popular Destinations</h5>
            <p class="theme-page-section-subtitle">Save your money with our best offers</p>
          </div>
          <div class="row row-col-mob-gap" data-gutter="10">
            <div class="col-md-3 ">
              <div class="banner _br-5 change-city banner-animate banner-animate-mask-in banner-animate-zoom-in">
                <img class="banner-img" src="assets/img/cty/mombasa.webp" alt="Image Alternative text" title="Image Title">
                <div class="banner-mask"></div>
                <a class="banner-link" (click)="clickCity(1)"></a>
                <div class="banner-caption _ta-c _pb-20 _pt-60 banner-caption-bottom banner-caption-grad">
                  <h5 class="banner-title _fs">Mombasa</h5>
                </div>
              </div>
            </div>
            <div class="col-md-3 ">
              <div class="banner _br-5 change-city banner-animate banner-animate-mask-in banner-animate-zoom-in">
                <img class="banner-img" src="assets/img/cty/kisumu.webp" alt="Image Alternative text" title="Image Title">
                <div class="banner-mask"></div>
                <a class="banner-link" (click)="clickCity(2)"></a>
                <div class="banner-caption _ta-c _pb-20 _pt-60 banner-caption-bottom banner-caption-grad">
                  <h5 class="banner-title _fs">Kisumu</h5>
                </div>
              </div>
            </div>
            <div class="col-md-3 ">
              <div class="banner _br-5 change-city banner-animate banner-animate-mask-in banner-animate-zoom-in">
                <img class="banner-img" src="assets/img/cty/diani.webp" alt="Image Alternative text" title="Image Title">
                <div class="banner-mask"></div>
                <a class="banner-link" (click)="clickCity(3)"></a>
                <div class="banner-caption _ta-c _pb-20 _pt-60 banner-caption-bottom banner-caption-grad">
                  <h5 class="banner-title _fs">Diani</h5>
                </div>
              </div>
            </div>
            <div class="col-md-3 ">
              <div class="banner _br-5 change-city banner-animate banner-animate-mask-in banner-animate-zoom-in">
                <img class="banner-img" src="assets/img/cty/eld.webp" alt="Image Alternative text" title="Image Title">
                <div class="banner-mask"></div>
                <a class="banner-link" (click)="clickCity(4)"></a>
                <div class="banner-caption _ta-c _pb-20 _pt-60 banner-caption-bottom banner-caption-grad">
                  <h5 class="banner-title _fs">Eldoret</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-col-mob-gap _mt-30" data-gutter="10">
            <div class="col-md-3 ">
              <div class="banner _br-5 change-city banner-animate banner-animate-mask-in banner-animate-zoom-in">
                <img class="banner-img" src="assets/img/cty/ams.jpg" alt="Image Alternative text" title="Image Title">
                <div class="banner-mask"></div>
                <a class="banner-link" (click)="clickCity(5)"></a>
                <div class="banner-caption _ta-c _pb-20 _pt-60 banner-caption-bottom banner-caption-grad">
                  <h5 class="banner-title _fs">Amsterdam</h5>
                </div>
              </div>
            </div>
            <div class="col-md-3 ">
              <div class="banner _br-5 change-city banner-animate banner-animate-mask-in banner-animate-zoom-in">
                <img class="banner-img" src="assets/img/cty/london.jpg" alt="Image Alternative text" title="Image Title">
                <div class="banner-mask"></div>
                <a class="banner-link" (click)="clickCity(6)"></a>
                <div class="banner-caption _ta-c _pb-20 _pt-60 banner-caption-bottom banner-caption-grad">
                  <h5 class="banner-title _fs">London</h5>
                </div>
              </div>
            </div>
            <div class="col-md-3 ">
              <div class="banner _br-5 change-city banner-animate banner-animate-mask-in banner-animate-zoom-in">
                <img class="banner-img" src="assets/img/cty/n_y.jpg" alt="Image Alternative text" title="Image Title">
                <div class="banner-mask"></div>
                <a class="banner-link" (click)="clickCity(7)"></a>
                <div class="banner-caption _ta-c _pb-20 _pt-60 banner-caption-bottom banner-caption-grad">
                  <h5 class="banner-title _fs">New York</h5>
                </div>
              </div>
            </div>
            <div class="col-md-3 ">
              <div class="banner _br-5 change-city banner-animate banner-animate-mask-in banner-animate-zoom-in">
                <img class="banner-img" src="assets/img/cty/r.jpg" alt="Image Alternative text" title="Image Title">
                <div class="banner-mask"></div>
                <a class="banner-link" (click)="clickCity(8)"></a>
                <div class="banner-caption _ta-c _pb-20 _pt-60 banner-caption-bottom banner-caption-grad">
                  <h5 class="banner-title _fs">Rome</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div class="theme-hero-area">
        <div class="theme-hero-area-body">
          <div class="theme-page-section-buupass ">
            <div class="buupass-container">
              <div class="wa_0_1pykh">

              </div>
            </div>
          </div>
        </div>
    </div>

    <!-- <div class="row row-col-border-white theme-page-section-download _p-20 hidden-sm hidden-md hidden-lg"  data-gutter="0">
          <div class="col-md-3 ">
            <div class="banner text-center">
              <h2 class="theme-mobile-app-title">Download our app today</h2>
              <p class="theme-mobile-app-subtitle">Book and manage your trips on the go. Be notified of our hot deals and offers.</p>
              <img alt="Download mobile app" src="assets/img/company1/mobile_1.png" style="height:250px;"  class="image-app" alt="">
              <a class="banner-link" href="https://play.google.com/store/apps/details?id=io.andronicus.buupass&hl=en_SG"></a>
              <ul class="theme-mobile-app-btn-list">

                <li>
                  <a class="btn btn-dark theme-mobile-app-btn _p-5" href="#">
                    <i class="theme-mobile-app-logo">
                      <img src="assets/img/brands/play-market.png"  alt="Image Alternative text" title="Image Title">
                    </i>
                    <span>Download on
                      <br>
                      <span>Play Store</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
    </div>

      <div class="theme-hero-area _mt-10">
        <div class="theme-hero-area-body">
          <div class="theme-page-section theme-page-section-buupass theme-page-section">
            <div class="buupass-container">
              <div class="theme-page-section-header _ta-l">
                <h2 class="destinationHeading">Top Destinations</h2>
                <p class="desSubHeading">These destinations are popular among travelers like you</p>
              </div>
              <div class="row">
                  <div class="col-md-12">
                    <div class="row row-col-gap" data-gutter="10">
                      <div (click)="nairobiKisumu()" class="col-md-5 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/kisumu1.jpg);"></div>
                          <div class="banner-mask"></div>
                          <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                                <h5 class="banner-title">Kisumu</h5>
                                <p class="banner-subtitle">Kenya</p>
                          </div>
                        </div>
                      </div>
                      <div (click)="nairobiKitale()" class="col-md-3 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/kitale.jpg);"></div>
                          <div class="banner-mask"></div>
                          <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                                <h5 class="banner-title">Kitale</h5>
                                <p class="banner-subtitle">Kenya</p>
                          </div>
                        </div>
                      </div>
                      <div  (click)="nairobiBungoma()" class="col-md-4 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/bungoma.jpg);"></div>
                          <div class="banner-mask"></div>

                          <div class="banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                            <h5 class="banner-title">Bungoma</h5>
                            <p class="banner-subtitle">Kenya</p>
                          </div>
                        </div>
                      </div>
                      <div (click)="nairobiKampala()" class="col-md-4 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/kampala.jpg);"></div>
                          <div class="banner-mask"></div>
                          <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                                <h5 class="banner-title">Kampala</h5>
                                <p class="banner-subtitle">Uganda</p>
                          </div>
                        </div>
                      </div>
                      <div  (click)="nairobiMombasa()" class="col-md-5 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/mombasa.jpg);"></div>
                          <div class="banner-mask"></div>
                          <div class="banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                            <h5 class="banner-title">Mombasa</h5>
                            <p class="banner-subtitle">Kenya</p>
                          </div>
                        </div>
                      </div>
                      <div (click)="nairobiBusia()" class="col-md-3 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/sbk40fdkbag_650x600.jpeg);"></div>
                          <div class="banner-mask"></div>
                          <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                                <h5 class="banner-title">Busia</h5>
                                <p class="banner-subtitle">Kenya</p>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
            </div>
            <div class="theme-page-section theme-page-section-sm visible-lg">
              <div class="buupass-container">
                <div class="theme-page-section-header theme-page-section-header-white">
                  <div class="buupass-home-section">
                    <div class="feature-list">

                        <div class="list-item">
                          <div class="row">

                              <div class="col-md-8 col-xs-8">
                                  <h5 class="text-center">Customers</h5>
                                <h2 class=" text-center text-pink text-stats">400K+</h2>
                                <p class="text-center">Total customers using Bus feature.</p>
                              </div>
                         </div>
                        </div>
                        <div class="list-item">
                          <div class="row">
                            <div class="col-md-8 col-xs-8">
                                <h5 class="text-center">Buses</h5>
                              <h2 class=" text-center text-pink text-stats">420+</h2>
                              <p class="text-center">Total buses in operations</p>
                            </div>
                         </div>
                        </div>
                        <div class="list-item">
                          <div class="row">
                            <div class="col-md-8 col-xs-8">
                                <h5 class="text-center">Routes</h5>
                              <h2 class=" text-center text-pink text-stats">115+</h2>
                              <p class="text-center">Total routes covered </p>
                            </div>
                         </div>
                        </div>
                        <div class="list-item">
                          <div class="row">
                            <div class="col-md-8 col-xs-8">
                                <h5 class="text-center">Tickets</h5>
                              <h2 class=" text-center text-pink text-stats">500+</h2>
                              <p class="text-center">Bus tickets sold</p>
                            </div>
                         </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div> -->
  <!-- <div  class="theme-hero-area theme-page-section theme-page-section-sm theme-page-section-offers visible-lg">
      <div class="theme-hero-area-body">
        <div class="container">
          <div class="theme-page-section _p-0">
            <div class="row">
              <div class="col-md-10 col-md-offset-1">
                <div class="theme-mobile-app">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="theme-mobile-app-section">
                        <img alt="Download mobile app" src="assets/img/company1/mobile_1.png" style="height:450px;"  class="image-app" alt="">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="theme-mobile-app-section">
                        <div class="theme-mobile-app-body">
                          <div class="theme-mobile-app-header">
                            <h2 class="theme-mobile-app-title">Download our app</h2>
                            <p class="theme-mobile-app-subtitle">Book and manage your trips on the go. Be notified of our hot deals and offers.</p>
                          </div>
                          <ul class="theme-mobile-app-btn-list">

                            <li>
                              <a class="btn btn-dark theme-mobile-app-btn _p-5" href="#">
                                <i class="theme-mobile-app-logo">
                                  <img src="assets/img/brands/play-market.png" alt="Image Alternative text" title="Image Title">
                                </i>
                                <span>Download on
                                  <br>
                                  <span>Play Store</span>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div> -->

  <app-footer></app-footer>
