import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RecentSearchService {
  private key = 'recentSearches';
  private limit = 5;  // Limit the number of recent searches stored

  getRecentSearches(): string[] {
    return JSON.parse(localStorage.getItem(this.key) || '[]');
  }

  addRecentSearch(searchTerm: string): void {
    let searches = this.getRecentSearches();
    searches = [searchTerm, ...searches.filter(term => term !== searchTerm)];
    if (searches.length > this.limit) {
      searches.pop();  // Remove the oldest search if limit is exceeded
    }
    localStorage.setItem(this.key, JSON.stringify(searches));
  }
}
