import { Injectable } from '@angular/core';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }
}


export const formatDateToYYYYMMDD = (date:Date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are 0-indexed
  const dd = String(date.getDate()).padStart(2, '0');

  return `${yyyy}-${mm}-${dd}`;
}


export const formatDateToYYYYMM = (dateInput:any) => {
  return moment(dateInput).format('MMM DD');
}
