import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minHour'
})
export class MinHourPipe implements PipeTransform {

  transform(d: any): string {
    d = Number(d);
    var h = Math.floor((d + 3600) / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " m " : " mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s" : " sec") : "";
    return hDisplay + mDisplay + sDisplay; 
  }

}
