import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItineraryServiceService, DataService, BookingService } from '../services/index'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LocationStrategy } from '@angular/common';
import countryData from './country.json';
import { Error } from '../models/index'
import { Base64 } from 'js-base64';
@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  countries: any = countryData;
  paytotalfare:any;
  private sub: any;
  bookingdetails:any;
  // flights
  flightdetails:any;
  flightSearchdetails:any;
  flightSearch:any;
  bookingData:any;
  logoUrl= 'https://images.kiwi.com/airlines/64x64/'
  logoUrlsmall='https://images.kiwi.com/airlines/32x32/'

  payeeEmail:any;
  payeePhoneno:any;
  customerPhone:any;
  payeeFirstName:any;
  payeeLastName:any;
  payeeAddress:any;
  api_error:Error;
  isError:boolean=false;

  int2:any;
  started=0;
  passBaggage =[];
  passBaggageValue:any;
  selectedFlight:any
  flightAerocrsdetails:any;
  ifAdultBagSelected=0;
  ifChildBagSelected=0;
  // end Flights
  returndetails:any;
  bookingdata:any={};
  bookingdataReturn:any={};
  passengers=[];
  fsname:any;
  lsname:any;
  isdno:any;
  phoneno:any;
  country:any;
  residence:any;
  returnpassengers=[];
  formValidation=[];

  errorMessage:any;
  res:any;
  is_return:false;
  loading=false;
  paymentForm: UntypedFormGroup;
  submitted = false;
  formDataObj={}
  counts = { adult: 0, child: 0, infant: 0 };
  groupedPassengers:any = {};
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service:ItineraryServiceService,
    private bookingService:BookingService,
    private dataService:DataService,
    private locationStrategy: LocationStrategy,
    private _fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    window.scroll(0,0);


    this.dataService.returnPaymentValueChange.subscribe(message =>{

      if(!message){
        message = localStorage.getItem('freviewd')
        if(!message) {
        //  this.router.navigate(['/'])
       }
      }else{
        localStorage.setItem('freviewd', message)
      }

      
      let encoded =JSON.parse(Base64.decode(message))
      this.isError= encoded.isError;
      this.bookingData=encoded.flight_data;
      this.flightSearchdetails=encoded.flight_data;
      this.countPassengerTypes(this.flightSearchdetails.passengers);
      this.groupPassengersByType(this.flightSearchdetails.passengers)
      
      // this.checkFlight(this.flightSearchdetails)

      if(this.groupedPassengers.adult.length > 0){
        for (let seat = 0; seat < this.groupedPassengers.adult.length; seat++) {
          let fsname='fname'+'a'+seat;
          let lsname='lname'+'a'+seat;
          let isdno='idno'+'a'+seat;
          let gender='gender'+'a'+seat;
          let title='title'+'a'+seat;
          let country = 'country'+ 'a'+ seat;
          let day_of_birth = 'day_of_birth'+ 'a'+ seat;
          let month_of_birth = 'month_of_birth'+ 'a'+ seat;
          let year_of_birth = 'year_of_birth'+ 'a'+ seat;


          if(seat == 0){
            let email='email'+'a'+seat;
            let phoneno = 'phoneno'+'a'+ seat;
            this.formDataObj[email]=['', [Validators.required, Validators.email]];
            this.formDataObj[phoneno]=['', [Validators.required, Validators.minLength(10),Validators.pattern('^(?:254|\\+254|0)(7|1)[0-9]{8}$')]];
          }

            this.formDataObj[fsname]=['', [Validators.required,Validators.pattern('^[a-zA-Z]+$')]];
            this.formDataObj[lsname]=['', [Validators.required,Validators.pattern('^[a-zA-Z]+$')]];
            this.formDataObj[isdno]=['', [Validators.required, Validators.minLength(7)]];
            this.formDataObj[gender]=['', Validators.required];
            this.formDataObj[title]=['', Validators.required];
            this.formDataObj[country]=['', Validators.required];
            this.formDataObj[day_of_birth]=['', [Validators.required,Validators.minLength(2),Validators.maxLength(2)]];
            this.formDataObj[month_of_birth]=['', [Validators.required]];
            this.formDataObj[year_of_birth]=['', [Validators.required,Validators.minLength(4),Validators.maxLength(4)]];
          
          
        };
      }
      if(this.counts.child > 0){
        for (let seat = 0; seat < this.groupedPassengers.child.length; seat++) {  
          let fsname='fname'+'c'+seat;
          let lsname='lname'+'c'+seat;
          let gender='gender'+'c'+seat;
          let country = 'country'+ 'c'+ seat;
          let day_of_birth = 'day_of_birth'+ 'c'+ seat;
          let month_of_birth = 'month_of_birth'+ 'c'+ seat;
          let year_of_birth = 'year_of_birth'+ 'c'+ seat;
          this.formDataObj[fsname]=['', [Validators.required,Validators.pattern('^[a-zA-Z]+$')]];
          this.formDataObj[lsname]=['', [Validators.required,Validators.pattern('^[a-zA-Z]+$')]];
          this.formDataObj[day_of_birth]=['', [Validators.required,Validators.minLength(2),Validators.maxLength(2)]];
          this.formDataObj[gender]=['', Validators.required];
          this.formDataObj[month_of_birth]=['', [Validators.required]];
          this.formDataObj[year_of_birth]=['', [Validators.required,Validators.minLength(4),Validators.maxLength(4)]];
          
          this.formDataObj[country]=['', Validators.required];
        };
      }
      if(this.counts.infant > 0){
        for (let seat = 0; seat < this.groupedPassengers.infant.length; seat++) { 
            let fsname='fname'+'i'+seat;
            let lsname='lname'+'i'+seat;
            let gender='gender'+'i'+seat;
            let country = 'country'+ 'i'+ seat;
            let day_of_birth = 'day_of_birth'+ 'i'+ seat;
            let month_of_birth = 'month_of_birth'+ 'i'+ seat;
            let year_of_birth = 'year_of_birth'+ 'i'+ seat;
            this.formDataObj[fsname]=['', [Validators.required,Validators.pattern('^[a-zA-Z]+$')]];
            this.formDataObj[lsname]=['', [Validators.required,Validators.pattern('^[a-zA-Z]+$')]];
            this.formDataObj[gender]=['', Validators.required];
            this.formDataObj[day_of_birth]=['', [Validators.required,Validators.minLength(2),Validators.maxLength(2)]];
            this.formDataObj[month_of_birth]=['', [Validators.required]];
            this.formDataObj[year_of_birth]=['', [Validators.required,Validators.minLength(4),Validators.maxLength(4)]];
          
            this.formDataObj[country]=['', Validators.required];
          };
      }


      // this.bookingdetails = JSON.parse(Base64.decode(encoded.travel));
      
      
    });

    this.paymentForm =this._fb.group(this.formDataObj);
  }


  countPassengerTypes(passengers) {
    passengers.forEach(passenger => {
      this.counts[passenger.type]++;
    });
  }

  groupPassengersByType(passengers) {
    this.groupedPassengers = passengers.reduce((acc, passenger) => {
      if (!acc[passenger.type]) {
        acc[passenger.type] = [];
      }
      acc[passenger.type].push(passenger);
      return acc;
    }, {});
  }

  counter(i: number) {
    return new Array(i);
}








  get f() { return this.paymentForm.controls; }


  checkIfArray(arr){
    return  Array.isArray(arr)
   }


calculateAgeFromBirthday(dateOfBirth) {
    let yearOfBirth = dateOfBirth.split("-"),
      currentYear = new Date().getFullYear();
    yearOfBirth = new Date(
      yearOfBirth[0],
      yearOfBirth[1] - 1,
      yearOfBirth[2]
    ).getFullYear();
    yearOfBirth = currentYear - yearOfBirth;
    return yearOfBirth;
  }

  submitForm(){
    clearInterval(this.int2);
    this.submitted=true;
    if (this.paymentForm.valid) {
        let value = this.paymentForm.value;
        this.isError=false;
        this.loading=true;
        this.passengers=[];
        // Get adults details
        if(this.counts.adult > 0){

          for (let seat = 0; seat < this.groupedPassengers.adult.length; seat++) {
          let passenger={};
          
          let fsname='fname'+'a'+seat;
          let lsname='lname'+'a'+seat;
          let isdno='idno'+'a'+seat;
          let gender='gender'+'a'+seat;
          let title='title'+'a'+seat;
          let country = 'country'+ 'a'+ seat;
          let day_of_birth = 'day_of_birth'+ 'a'+ seat;
          let month_of_birth = 'month_of_birth'+ 'a'+ seat;
          let year_of_birth = 'year_of_birth'+ 'a'+ seat;
          
          if(seat == 0){
             let memail='email'+'a'+seat;
             let mphoneno = 'phoneno'+'a'+ seat;
             this.payeeEmail=value[memail]
             this.customerPhone='254' + value[mphoneno].slice(-9),
             this.payeePhoneno=value[mphoneno], 
             this.payeeFirstName=value[fsname]
             this.payeeLastName=value[lsname]
             this.payeeAddress=value[country]
          }

            passenger={
              "phone_number": this.payeePhoneno,
              "phone_code": "+254",
              "email": this.payeeEmail,
              "born_on": value[year_of_birth]+'-'+value[month_of_birth]+'-'+value[day_of_birth],
              "title": (value[title] == 'mr' ) ? 'Mr' : (value[title] == 'mrs' ) ? 'Mrs' : 'Miss',
              "gender": value[gender],
              "family_name": value[lsname],
              "given_name": value[fsname],
              "id_or_passport":value[isdno],
              "nationality":value[country],
              "type": "adult"
            }


      
         
      this.passengers.push(passenger);
    };
  }
      // get children details 
      if(this.counts.child > 0){
        for (let seat = 0; seat < this.groupedPassengers.child.length; seat++) {
        let passenger={};
        let fsname='fname'+'c'+seat;
        let lsname='lname'+'c'+seat;
        let isdno='idno'+'c'+seat;
        let gender='gender'+'c'+seat;
        let country = 'country'+'c'+ seat;
        let day_of_birth = 'day_of_birth'+'c'+ seat;
        let month_of_birth = 'month_of_birth'+'c'+ seat;
        let year_of_birth = 'year_of_birth'+'c'+ seat;

          passenger={
              "phone_number": this.payeePhoneno,
              "phone_code": "+254",
              "email": this.payeeEmail,
              "born_on": value[year_of_birth]+'-'+value[month_of_birth]+'-'+value[day_of_birth],
              "title": 'Child',
              "gender": value[gender],
              "family_name": value[lsname],
              "given_name": value[fsname],
              "id_or_passport":value[isdno],
              "nationality":value[country],
              "type": "child"
          }

          this.passengers.push(passenger);
    };
    }

          // get infant details 
          if(this.counts.infant > 0){

            for (let seat = 0; seat < this.groupedPassengers.infant.length; seat++) {
            let passenger={};
            let fsname='fname'+'i'+seat;
            let lsname='lname'+'i'+seat;
            let isdno='idno'+'i'+seat;
            let gender='gender'+'i'+seat;
            let country = 'country'+'i'+ seat;
            let day_of_birth = 'day_of_birth'+'i'+ seat;
            let month_of_birth = 'month_of_birth'+'i'+ seat;
            let year_of_birth = 'year_of_birth'+'i'+ seat;

            passenger={
                "phone_number": this.payeePhoneno,
                "phone_code": "+254",
                "email": this.payeeEmail,
                "born_on": value[year_of_birth]+'-'+value[month_of_birth]+'-'+value[day_of_birth],
                "title": 'Infant',
                "gender": value[gender],
                "family_name": value[lsname],
                "given_name": value[fsname],
                "nationality":value[country],
                "id_or_passport":value[isdno],
                "type": "infant"
              }
  
          this.passengers.push(passenger);
        };
        }

    }
    

    let bookData = {
      'passengers': this.passengers,
      "offer_id": this.bookingData.id,
      "result_id": this.bookingData.result_id,
      "payments": {
        "type": "mpesa",
        "currency": "KES",
        "amount": this.bookingData.total_amount
      },
      "customer":{
        "phone_number": this.customerPhone,
        "email": this.payeeEmail,
        "first_name": this.payeeFirstName,
        "last_name": this.payeeLastName,
        "address": this.payeeAddress
      },
      "gds":this.bookingData.gds
    }






    this.loading=true;

      this.service.holdFlightBooking(bookData).subscribe(
        data  => {
          this.res=data;
          if(this.res.success = true){

          let payee = {
            "booking_reference":this.res.data.booking_reference,
            "payments":"mpesa",
            "amount":1.0,
            "customer":{
              "phone_number": this.customerPhone,
              "email": this.payeeEmail,
              "first_name": this.payeeFirstName,
              "last_name": this.payeeLastName,
              "address": this.payeeAddress
            }
        }

            let others={
              'data':this.res.data,
              'payee':payee,
              'seach_details':this.flightSearchdetails,
              'search_data':this.flightSearch,
              'booking_data':this.bookingData
            };
  
            let f=Base64.encode(JSON.stringify(others))
            this.router.navigate(['/confirm-payments', f])
          }else{
            this.res
            this.loading=false;
            this.isError=true;
            this.errorMessage=this.res.data.message;
        }
      

        },
        error  => {
          this.loading=false;
          this.isError=true;
          this.errorMessage="Sorry there has been a technical problem.Please try again ";
          this.api_error=new Error().deserialize(error.error.errors)
  
        }
      );
    
    
  }

}
