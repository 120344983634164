import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeDiff',
  pure: false
})
export class TimeDiffPipe implements PipeTransform {

  transform(tm1: any, tim2: any): any {

    var time_start = new Date();
    var time_end = new Date();

    var value_start = tm1.split(':');
    var value_end = tim2.split(':'); 

    time_start.setHours(value_start[0], value_start[1], 0, 0)
    time_end.setHours(value_end[0], value_end[1], 0, 0)

    var new_time_start=this.toTimestamp(time_start)
    var new_time_end=this.toTimestamp(time_end)

    var tdiffh = new_time_end - new_time_start
    var new_t_diff=this.toMinsHours(tdiffh)

    return new_t_diff
  }

  ftime(value: string): string {
    let n = value.split(":")
    let hr =n[0]
    let min =n[1]
    return hr +':'+ min;
  }

  toTimestamp(strDate){
    var datum = Date.parse(strDate);
    return datum/1000;
 }

 toMinsHours(d: any): string {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " m " : " mins ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " s" : " sec") : "";
  return hDisplay + mDisplay + sDisplay; 
}

}
