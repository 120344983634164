import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {Destinations } from '../models/index'
import  { environment } from '../../environments/environment'
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http:HttpClient
  ) { }

  getHttpOptions(): any {
    const token = this.getToken();  // Assuming 'this.getToken()' is defined in the same service
    if (!token) {
      console.error('Token not found!');  // Handle the error appropriately
      return {};
    }
  
    return {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`
      })
    };
  }

  getKiwiDestinations(term) {
    return this
            .http
            .get<Destinations[]>(`${environment.getKiwiCities}` + term ,this.getHttpOptions());
  }

  getDestinations(term): Observable<any> {
  
    return this
            .http
            .get<Destinations[]>(`${environment.getCities}` + term + '&schedule_type=express',this.getHttpOptions());
  }

  getDuqaDestinations(term) {
    return this
            .http
            .get<Destinations[]>(`${environment.getCities}` + term + '&schedule_type=express',this.getHttpOptions());
  }

  // getApiToken(data) {
  //   return this
  //           .http
  //           .post<any>(`${environment.getToken}`,data,httpOptions);
  // }

  
  getCredentials() {
    return this
            .http
            .get<Destinations[]>(`${environment.getTokenCred}`);
  }


  getApiToken(data: { client_id: string, client_secret: string }): Observable<any> {
    const tokenUrl = `${environment.getTokenCred}`;
    return this.http.post(tokenUrl, data);
  }

  saveToken(token: string): void {
    localStorage.setItem('access_token', token);
  }

  getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  // saveToken(access_token){
  //   localStorage.setItem('fbindings',access_token);
  // }
  
 

}
